import React from 'react';

const IconHelp = () => {
  return (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <title>Help</title>

      <g>
        <title>background</title>
        <rect fill="none" id="canvas_background" height="102" width="102" y="-1" x="-1" />
      </g>
      <g>
        <title>Help</title>
        <path id="svg_1" d="m50,9a41,41 0 1 1 -41,41a41,41 0 0 1 41,-41m0,-9a50,50 0 1 0 50,50a50,50 0 0 0 -50,-50z" />
        <path
          id="svg_2"
          d="m49.73,60.61l-3.65,0a1.12,1.12 0 0 1 -1.21,-1.15a37.71,37.71 0 0 1 0.74,-9.36a8.53,8.53 0 0 1 2.25,-4c1,-1.06 2.08,-2 3.16,-3s2.37,-2.1 3.59,-3.1c2.4,-2 2.11,-5.48 -0.89,-7.12a7,7 0 0 0 -9.8,3.45a13.7,13.7 0 0 0 -0.6,1.88c-0.24,0.87 -0.64,1.18 -1.54,1.07l-7.13,-0.86a1.26,1.26 0 0 1 -1.18,-1.52a14.61,14.61 0 0 1 12.1,-12.9a20.73,20.73 0 0 1 12.59,1.16a13.59,13.59 0 0 1 7.65,8a10.58,10.58 0 0 1 -1.66,10.56a37.87,37.87 0 0 1 -5.76,5.63c-0.67,0.58 -1.31,1.2 -2,1.77a4.52,4.52 0 0 0 -1.65,3.65c0,1.53 -0.1,3.05 -0.14,4.58a1.23,1.23 0 0 1 -1.35,1.35l-3.52,0l0,-0.09z"
        />
        <path id="svg_3" d="m54.89,71.5a5,5 0 1 1 -5,-5a5,5 0 0 1 5,5z" />
      </g>
    </svg>
  );
};

export default IconHelp;
