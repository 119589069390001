import React, { useRef, useState } from 'react';
import Link from 'next/link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { Button } from 'reactstrap';
import Bin from './Bin';
import Import from './Import';
import IconBin from '../svg/IconBin';
import IconHelp from '../svg/IconHelp';

type SidebarProps = {
  showLoaded?: boolean;
  showImport?: boolean;
  loaded?: any;
  mediaFolders?: ProjectFolder[];
  projectId?: string;
  userHasWritePermission?: boolean;
  isModeVideoEdit?: boolean;
  handleChangeSelected?: (selectedMedia: ImportedMedia) => void;
  handleCreateMediaFolder?: (folderName: string, callback: Function) => void;
  handleAmendMedia?: (mediaId: string, folderId: string | null) => void;
  openModalConfirmDeleteMedia?: (media: ImportedMedia) => void;
  handleAmendMediaFolder?: (folderId: string, folderName: string) => void;
  handleArchiveMediaFolder?: (folder: ProjectFolder) => void;
};

const Sidebar = ({
  showLoaded = true,
  showImport = true,
  loaded,
  mediaFolders,
  projectId,
  userHasWritePermission,
  isModeVideoEdit,
  handleChangeSelected,
  openModalConfirmDeleteMedia,
  handleCreateMediaFolder,
  handleAmendMedia,
  handleAmendMediaFolder,
  handleArchiveMediaFolder,
}: SidebarProps) => {
  const simonEyeRef = useRef<HTMLElement>(null);
  const sidebarRef = React.createRef<HTMLElement>();

  const [loadedPanelActive, setLoadedPanelActive] = useState(false);
  const [importPanelActive, setImportPanelActive] = useState(false);

  const isOpen = loadedPanelActive || importPanelActive;

  useEffect(() => {
    (function loop() {
      const rand = Math.round(Math.random() * (3000 - 500)) + 1000;
      setTimeout(function() {
        simonBlink(rand, loop);
      }, rand);
    })();
  }, []);

  // using class toggle instead of conditional rendering to enable animation
  useEffect(() => {
    if (isOpen) {
      sidebarRef.current && sidebarRef.current.classList.add('active');
    } else {
      sidebarRef.current && sidebarRef.current.classList.remove('active');
    }
  }, [sidebarRef, isOpen]);

  // handle click outside sidebar
  useEffect(() => {
    const handleClickOutsideSidebar: EventListener = (event: Event): void => {
      if (event.target && sidebarRef.current && !sidebarRef.current.contains(event.target as HTMLElement)) {
        setLoadedPanelActive(false);
        setImportPanelActive(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutsideSidebar);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSidebar);
    };
  }, [sidebarRef]);

  function simonBlink(time: number, callBack?: Function) {
    if (simonEyeRef?.current) {
      if (simonEyeRef.current.classList.contains('js-blink')) return;
      simonEyeRef.current.classList.add('js-blink');
      setTimeout(() => {
        if (simonEyeRef?.current) simonEyeRef.current.classList.remove('js-blink');
        if (callBack) callBack();
      }, time + 500);
    }
  }

  const toggleVideo = () => {
    setImportPanelActive(false);
    setLoadedPanelActive(!loadedPanelActive);
  };

  const toggleFolder = () => {
    setImportPanelActive(!importPanelActive);
    setLoadedPanelActive(false);
  };

  const handleMediaClicked = (media: ImportedMedia): void => {
    setLoadedPanelActive(false);
    setImportPanelActive(false);
    if (handleChangeSelected) {
      handleChangeSelected(media);
    }
  };

  const onImportSuccesful = (_data: ImportSuccesfulData): void => {
    setLoadedPanelActive(true);
    setImportPanelActive(false);
  };

  return (
    <nav id="sidebar" ref={sidebarRef}>
      <ul className="list-unstyled sidebar-items">
        <li onMouseEnter={() => simonBlink(0)}>
          <div>
            <div title="Back to dashboard" className="sidebar-icon sidebar-logo">
              <Link href="/">
                <a>
                  <img src="/simonsays-no-eye-logo.png" className="logo" alt="Simon Says" />
                  <span ref={simonEyeRef} className="sidebar-logo-eye blink"></span>
                </a>
              </Link>
            </div>
            <div className="sidebar-text sidebar-top"></div>
          </div>
        </li>
        <React.Fragment>
          {showLoaded && (
            <li title="Bin of project media">
              <div onClick={toggleVideo} className={`sidebar-row ${loadedPanelActive ? 'selected' : ''}`}>
                <div className="sidebar-icon">
                  <Button color="link">
                    <IconBin />
                  </Button>
                </div>
                <div className="sidebar-text">
                  <span>BIN</span>
                </div>
              </div>
            </li>
          )}
        </React.Fragment>
        <React.Fragment>
          {showImport && (
            <li title="Import media">
              <div onClick={toggleFolder} className={`sidebar-row ${importPanelActive ? 'selected' : ''}`}>
                <div className="sidebar-icon">
                  <Button color="link">
                    <FontAwesomeIcon icon={faFileUpload} width={24} height={16} />
                  </Button>
                </div>
                <div className="sidebar-text">
                  <span>IMPORT</span>
                </div>
              </div>
            </li>
          )}
        </React.Fragment>

        <li className="sidebar-question-mark sidebar-row">
          <a href="https://help.simonsays.ai/en/collections/2667891-simon-says-assemble" target="_blank">
            <div className="sidebar-icon">
              <IconHelp />
            </div>
          </a>
        </li>
      </ul>
      {loadedPanelActive && (
        <Bin
          mediaFolders={mediaFolders}
          loaded={loaded}
          userHasWritePermission={userHasWritePermission}
          handleChangeSelected={handleMediaClicked}
          handleCreateMediaFolder={handleCreateMediaFolder}
          handleAmendMedia={handleAmendMedia}
          handleAmendMediaFolder={handleAmendMediaFolder}
          openModalConfirmDeleteFolder={(folder: ProjectFolder) => {
            if (handleArchiveMediaFolder) {
              // setLoadedPanelActive(false);
              // setImportPanelActive(false);
              handleArchiveMediaFolder(folder);
            }
          }}
          openModalConfirmDeleteMedia={(media: ImportedMedia) => {
            if (openModalConfirmDeleteMedia) {
              setLoadedPanelActive(false);
              setImportPanelActive(false);
              openModalConfirmDeleteMedia(media);
            }
          }}
        />
      )}
      {importPanelActive && <Import onImportSuccesful={onImportSuccesful} projectId={projectId} />}
    </nav>
  );
};

export default Sidebar;
