import React from 'react';

const IconBin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 100 100"
      x="0px"
      y="10px"
      className="svg-inline--fa fa-file-upload fa-w-12 "
      width="16"
      height="16"
    >
      <path fill="currentColor" d="M42,82H58a8,8,0,0,0,8-8V70a4,4,0,0,0-8,0v4H42V70a4,4,0,0,0-8,0v4A8,8,0,0,0,42,82Z" />
      <path
        fill="currentColor"
        d="M97.79,52.73,84.53,12.94A16,16,0,0,0,69.35,2H30.65A16,16,0,0,0,15.47,12.94L2.21,52.73a2.89,2.89,0,0,0-.08.65A3.25,3.25,0,0,0,2,54V78A20,20,0,0,0,22,98H78A20,20,0,0,0,98,78V54a3.25,3.25,0,0,0-.13-.62A2.89,2.89,0,0,0,97.79,52.73ZM23.06,15.47A8,8,0,0,1,30.65,10h38.7a8,8,0,0,1,7.59,5.47L88.45,50H11.55ZM90,78A12,12,0,0,1,78,90H22A12,12,0,0,1,10,78V58H90Z"
      />
      <path fill="currentColor" d="M30,42H70a4,4,0,0,0,0-8H30a4,4,0,0,0,0,8Z" />
      <path fill="currentColor" d="M34,26H66a4,4,0,0,0,0-8H34a4,4,0,0,0,0,8Z" />
    </svg>
  );
};

export default IconBin;
